<template>
  <div>
    <vs-card>
      <div slot:header class="border-b-2 flex justify-between m-5">
        <h1>Create Clinic</h1>
        <vs-button @click="ceateClinic">Create</vs-button>
      </div>
      <!-- Select Organization -->
      <div class="w-1/2 my-5">
        <form autocomplete="off">
          <!-- Clinic Details -->
          <div class="mx-5">
            <label class="font-bold">Clinic Details</label>
            <div class="w-full mt-5">
              <vs-select
                :disabled="$route.params.organizationId"
                v-validate="'required'"
                data-vv-as="visible to"
                name="organizationId"
                placeholder="Choose Organization"
                autocomplete
                v-model="clinicDetails.organizationId"
                class="w-full"
                val-icon-success="done"
                val-icon-danger="error"
                :danger="errors.first('basic.organizationId')"
                :danger-text="errors.first('basic.organizationId')"
                data-vv-scope="basic"
                label="Assign Organization"
                :success="
                  !errors.first('basic.organizationId') &&
                  clinicDetails.organizationId != ''
                "
              >
                <vs-select-item
                  v-for="(item, index) in organizations"
                  :key="index"
                  :value="item._id"
                  :text="item.name"
                />
              </vs-select>
            </div>
            <div class="w-full mt-5">
              <vs-input
                val-icon-success="done"
                val-icon-danger="error"
                v-validate="{ required: true }"
                name="clinicName"
                :danger="errors.first('basic.clinicName')"
                :danger-text="errors.first('basic.clinicName')"
                data-vv-scope="basic"
                data-vv-as="clinicName"
                label="Clinic Name"
                class="w-full"
                v-model="clinicDetails.clinicName"
                :success="
                  !errors.first('basic.clinicName') &&
                  clinicDetails.clinicNamel != ''
                "
                placeholder="Clinic Name"
              />
            </div>

            <!-- <div class="w-full mt-5" v-if="region === 'US'"> -->
            <div id="geocoder" />
            <!-- </div> -->

            <div class="w-full mt-5">
              <vs-input
                :danger="errors.first('city') ? true : false"
                :success="
                  !errors.first('city') && clinicDetails.address.suburb != ''
                "
                val-icon-success="done"
                val-icon-danger="error"
                data-vv-as="clinicDetails.address.suburb"
                name="city"
                label="City"
                placeholder="City"
                v-model="clinicDetails.address.suburb"
                class="w-full"
              />
            </div>

            <div class="w-full mt-5">
              <vs-input
                :danger="errors.first('state') ? true : false"
                :success="
                  !errors.first('state') && clinicDetails.address.state != ''
                "
                val-icon-success="done"
                val-icon-danger="error"
                data-vv-as="state"
                name="state"
                label="State"
                placeholder="State"
                v-model="clinicDetails.address.state"
                class="w-full"
              />
            </div>

            <div class="w-full mt-5">
              <vs-input
                :danger="errors.first('postCode') ? true : false"
                :success="
                  !errors.first('postCode') &&
                  clinicDetails.address.postCode != ''
                "
                val-icon-success="done"
                val-icon-danger="error"
                data-vv-as="postCode"
                name="postCode"
                label="Zipcode"
                placeholder="Zipcode"
                v-model="clinicDetails.address.postCode"
                class="w-full"
              />
            </div>

            <!-- <div class="w-full mt-5" v-if="region !== 'US'">
              <vs-input
                :danger="errors.first('basic.address') ? true : false"
                :danger-text="errors.first('basic.address')"
                data-vv-scope="basic"
                :success="
                  !errors.first('basic.address') &&
                  clinicDetails.address.displayAddress != ''
                "
                ref="autocomplete"
                val-icon-success="done"
                val-icon-danger="error"
                v-validate="'required'"
                data-vv-as="address"
                name="address"
                label="Clinic Address"
                placeholder="Clinic address"
                v-model="clinicDetails.address.displayAddress"
                class="w-full"
              />
            </div> -->

            <div class="w-full mt-5" v-if="region !== 'US'">
              <vs-input
                :danger="errors.first('postalAddress') ? true : false"
                :success="
                  !errors.first('postalAddress') &&
                  clinicDetails.postalAddress != ''
                "
                val-icon-success="done"
                val-icon-danger="error"
                data-vv-as="postalAddress"
                name="postalAddress"
                label="Postal Address"
                placeholder="Postal Address"
                v-model="clinicDetails.postalAddress"
                class="w-full"
              />
            </div>

            <div class="w-full mt-5">
              <vs-input
                :danger="errors.first('basic.bestContactNumber') ? true : false"
                :danger-text="errors.first('basic.bestContactNumber')"
                :success="
                  !errors.first('basic.bestContactNumber') &&
                  clinicDetails.bestContactNumber != ''
                "
                data-vv-scope="basic"
                val-icon-success="done"
                val-icon-danger="error"
                v-validate="'required'"
                data-vv-as="bestContactNumber"
                name="bestContactNumber"
                label="Contact #"
                placeholder="Contact #"
                v-model="clinicDetails.bestContactNumber"
                class="w-full"
              />
            </div>

            <div class="w-full mt-5">
              <vs-input
                label="Clinic Aftercare Email"
                placeholder="Clinic Aftercare Email"
                v-model="clinicDetails.bestContactEmail"
                class="w-full"
              />
            </div>
          </div>

          <div class="mx-5 mt-10">
            <!-- Clinic Settings -->
            <div class="w-full mt-10">
              <label class="font-bold">Clinic Settings</label>
              <p>
                Some clinic settings are inherited from the organisation this
                clinic is assigned to. These can be changed here if specific
                clinic settings are required.
              </p>

              <div class="w-full mt-5">
                <vs-input
                  :danger="errors.first('basic.invoiceEmail') ? true : false"
                  :danger-text="errors.first('basic.invoiceEmail')"
                  data-vv-scope="basic"
                  :success="
                    !errors.first('basic.invoiceEmail') &&
                    clinicDetails.invoiceEmail != ''
                  "
                  val-icon-success="done"
                  val-icon-danger="error"
                  v-validate="'required|email'"
                  data-vv-as="invoiceEmail"
                  name="invoiceEmail"
                  label="Monthly Treatment Invoice Email"
                  v-model="clinicDetails.invoiceEmail"
                  class="w-full"
                />
              </div>
              <div class="w-full mt-5">
                <vs-input
                  :danger="
                    errors.first('treatmentConsultationFee') ? true : false
                  "
                  :success="
                    !errors.first('treatmentConsultationFee') &&
                    clinicDetails.treatmentConsultationFee != ''
                  "
                  val-icon-success="done"
                  val-icon-danger="error"
                  data-vv-as="treatmentConsultationFee"
                  name="treatmentConsultationFee"
                  placeholder="25"
                  label="Treatment consultation Fee(default is $25)"
                  v-model="clinicDetails.treatmentConsultationFee"
                  class="w-full"
                />
              </div>
            </div>
          </div>

          <div class="mx-5 mt-10">
            <label class="font-bold">Is BYO Doctor</label>
            <div class="flex">
              <div class="mt-5">
                <label>Is BYO Doctor</label>
                <vs-switch
                  v-model="clinicDetails.isBYODoctor"
                />
              </div>
            </div>
          </div>

          <div class="mx-5 mt-5 mb-10">
            <label class="font-bold">Test Clinic Settings</label>
            <div class="flex">
              <div class="mt-5">
                <label>Test Clinic</label>
                <vs-switch v-model="clinicDetails.isTestClinic" />
              </div>
            </div>
          </div>

          <div class="mx-5 mt-5 mb-10">
            <div class="flex">
              <div class="mt-5">
                <label>Training clinic</label>
                <vs-switch v-model="clinicDetails.isTrainingClinic" />
              </div>
            </div>
          </div>

          <div class="mx-5 mt-5 mb-10">
            <div class="flex">
              <div class="mt-5">
                <label>Staff clinic</label>
                <vs-switch v-model="clinicDetails.isStaffClinic" />
              </div>
            </div>
          </div>

          <!-- Drug book -->
          <div class="mx-5 mt-10">
            <label class="font-bold">Drug Book Settings</label>
            <div class="flex">
              <div class="mt-5">
                <label>Enable Drug Book</label>
                <vs-switch
                  v-model="clinicDetails.isDrugBookEnabled"
                  :disabled="!organizationDrugbookOn"
                />
              </div>
            </div>
          </div>

          <!-- Ecommerce Settings -->
          <div class="mx-5 mt-10">
            <label class="font-bold">Ecommerce Settings</label>
            <div class="flex">
              <div class="mt-5">
                <label>Access the store</label>
                <vs-switch v-model="clinicDetails.showStore" />
              </div>
            </div>

            <div v-if="clinicDetails.showStore">
              <div class="mt-5">
                <label>Clinic Hours of Operation</label>
                <div class="w-full mt-5 flex justify-between">
                  <div class="w-1/5 flex items-center">
                    <label>Monday</label>
                  </div>
                  <div class="w-4/5">
                    <vs-input
                      val-icon-success="done"
                      val-icon-danger="error"
                      data-vv-as="clinicOpeningHours"
                      name="clinicOpeningHours"
                      placeholder="9-5 or closed"
                      v-model="clinicDetails.clinicOpeningHours.mondays"
                      class="w-full"
                    />
                  </div>
                </div>

                <div class="w-full mt-5 flex justify-between">
                  <div class="w-1/5 flex items-center">
                    <label>Tuesday</label>
                  </div>
                  <div class="w-4/5">
                    <vs-input
                      val-icon-success="done"
                      val-icon-danger="error"
                      data-vv-as="clinicOpeningHours"
                      name="clinicOpeningHours"
                      placeholder="9-5 or closed"
                      v-model="clinicDetails.clinicOpeningHours.tuesdays"
                      class="w-full"
                    />
                  </div>
                </div>

                <div class="w-full mt-5 flex justify-between">
                  <div class="w-1/5 flex items-center">
                    <label>Wednesday</label>
                  </div>
                  <div class="w-4/5">
                    <vs-input
                      val-icon-success="done"
                      val-icon-danger="error"
                      data-vv-as="clinicOpeningHours"
                      name="clinicOpeningHours"
                      placeholder="9-5 or closed"
                      v-model="clinicDetails.clinicOpeningHours.wednesdays"
                      class="w-full"
                    />
                  </div>
                </div>

                <div class="w-full mt-5 flex justify-between">
                  <div class="w-1/5 flex items-center">
                    <label>Thursday</label>
                  </div>
                  <div class="w-4/5">
                    <vs-input
                      val-icon-success="done"
                      val-icon-danger="error"
                      data-vv-as="clinicOpeningHours"
                      name="clinicOpeningHours"
                      placeholder="9-5 or closed"
                      v-model="clinicDetails.clinicOpeningHours.thursdays"
                      class="w-full"
                    />
                  </div>
                </div>

                <div class="w-full mt-5 flex justify-between">
                  <div class="w-1/5 flex items-center">
                    <label>Friday</label>
                  </div>
                  <div class="w-4/5">
                    <vs-input
                      val-icon-success="done"
                      val-icon-danger="error"
                      data-vv-as="clinicOpeningHours"
                      name="clinicOpeningHours"
                      placeholder="9-5 or closed"
                      v-model="clinicDetails.clinicOpeningHours.fridays"
                      class="w-full"
                    />
                  </div>
                </div>

                <div class="w-full mt-5 flex justify-between">
                  <div class="w-1/5 flex items-center">
                    <label>Saturday</label>
                  </div>
                  <div class="w-4/5">
                    <vs-input
                      val-icon-success="done"
                      val-icon-danger="error"
                      data-vv-as="clinicOpeningHours"
                      name="clinicOpeningHours"
                      placeholder="9-5 or closed"
                      v-model="clinicDetails.clinicOpeningHours.saturdays"
                      class="w-full"
                    />
                  </div>
                </div>

                <div class="w-full mt-5 flex justify-between">
                  <div class="w-1/5 flex items-center">
                    <label>Sunday</label>
                  </div>
                  <div class="w-4/5">
                    <vs-input
                      val-icon-success="done"
                      val-icon-danger="error"
                      data-vv-as="clinicOpeningHours"
                      name="clinicOpeningHours"
                      placeholder="9-5 or closed"
                      v-model="clinicDetails.clinicOpeningHours.sundays"
                      class="w-full"
                    />
                  </div>
                </div>

                <!--
            <clinic-opening-hours
              ref="stepTwoRef"
              :weekDaysData="clinicDetails.clinicOpeningHours"
              :validate="clinicDetails.showStore"
              from="create"
            />
          -->
                <div class="mt-10">
                  <vs-checkbox v-model="clinicDetails.isDoctorStore">
                    Apply doctor store pricing
                  </vs-checkbox>
                </div>
                <div class="w-full mt-10">
                  <vs-input
                    data-vv-scope="basic"
                    val-icon-success="done"
                    val-icon-danger="error"
                    data-vv-as="orderRecipients"
                    name="orderRecipients"
                    placeholder="Store receipts & confirmation emails"
                    label="Store receipts & confirmation emails"
                    v-model="clinicDetails.orderRecipients"
                    class="w-full"
                  />
                </div>

                <div class="w-full mt-5">
                  <label>Payment Types</label>
                  <vs-select
                    :danger="errors.first('basic.paymentTypes') ? true : false"
                    :danger-text="errors.first('basic.paymentTypes')"
                    :success="
                      !errors.first('basic.paymentTypes') &&
                      clinicDetails.paymentTypes != ''
                    "
                    data-vv-scope="basic"
                    val-icon-success="done"
                    val-icon-danger="error"
                    data-vv-as="paymentTypes"
                    name="paymentTypes"
                    v-model="clinicDetails.paymentTypes"
                    class="w-full"
                    multiple
                    v-validate="`required`"
                    autocomplete
                  >
                    <vs-select-item
                      v-for="(item, index) in paymentTypesOptions"
                      :key="index"
                      :value="item.value"
                      :text="item.text"
                    />
                  </vs-select>
                </div>

                <div class="w-full mt-5">
                  <label>Suppliers</label>
                  <vs-select
                    :danger="
                      errors.first('basic.clinicAssignedSuppliers')
                        ? true
                        : false
                    "
                    :danger-text="errors.first('basic.clinicAssignedSuppliers')"
                    :success="
                      !errors.first('basic.clinicAssignedSuppliers') &&
                      clinicDetails.clinicAssignedSuppliers != ''
                    "
                    data-vv-scope="basic"
                    val-icon-success="done"
                    val-icon-danger="error"
                    data-vv-as="clinicAssignedSuppliers"
                    name="clinicAssignedSuppliers"
                    v-model="clinicDetails.clinicAssignedSuppliers"
                    class="w-full"
                    multiple
                    v-validate="'required'"
                    autocomplete
                  >
                    <vs-select-item
                      v-for="(item, index) in suppliers"
                      :key="index"
                      :value="item._id"
                      :text="item.name"
                    />
                  </vs-select>
                </div>

                <div class="w-full mt-5">
                  <label>Clinic Discount</label>
                  <vs-select
                    data-vv-scope="basic"
                    val-icon-success="done"
                    val-icon-danger="error"
                    data-vv-as="clinicDiscounts"
                    name="clinicDiscounts"
                    v-model="clinicDetails.clinicDiscounts"
                    class="w-full"
                    multiple
                    autocomplete
                  >
                    <vs-select-item
                      v-for="(item, index) in discounts"
                      :key="index"
                      :value="item._id"
                      :text="item.name"
                    />
                  </vs-select>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </vs-card>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { Validator } from "vee-validate";
import ClinicOpeningHours from "../../components/clinics/ClinicOpeningHours.vue";
import Button from "../../components/vuesax/button/Button.vue";
import mapboxgl from "mapbox-gl";
import MapboxGeocoder from "@mapbox/mapbox-gl-geocoder";
import "@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css";

const dict = {
  custom: {
    clinicName: {
      required: "Please enter clinic name",
    },
    address: {
      required: "Please enter address",
    },
    city: {
      required: "Please enter city",
    },
    state: {
      required: "Please enter state",
    },
    postCode: {
      required: "Please enter postCode",
    },
    bestContactNumber: {
      required: "Please enter your best contact number",
    },
    orderRecipients: {
      required: "Please enter your best contact number",
    },
    invoiceEmail: {
      required: "Please enter order recipients",
      email: "The monthly treatment invoice email field must be a valid email",
    },
  },
};

Validator.localize("en", dict);

export default {
  components: {
    ClinicOpeningHours,
    Button,
  },
  data: () => ({
    accessToken: process.env.VUE_APP_MAPBOX_API_KEY,
    clinicDetails: {
      clinicName: "",
      organizationId: "",
      address: {
        displayAddress: "",
        state: "",
        suburb: "",
        postCode: "",
        coordinates: {
          latitude: 0,
          longitude: 0,
        },
      },
      // postalAddress: "",
      bestContactNumber: "",
      bestContactEmail: "",
      bestContactName: "name",
      abn: "12345678900",
      clinicOpeningHours: {
        mondays: "",
        tuesdays: "",
        wednesdays: "",
        thursdays: "",
        fridays: "",
        saturdays: "",
        sundays: "",
      },
      invoiceEmail: "",
      treatmentConsultationFee: "25",
      paymentTypes: [],
      showStore: false,
      isDoctorStore: false,
      orderRecipients: "", // from array
      clinicDiscounts: [],
      clinicAssignedSuppliers: [],
      isTestClinic: false,
      isTrainingClinic: false,
      isStaffClinic: false,
      isDrugBookEnabled: false,
      isTaxable: false,
      isBYODoctor: false,
    },
    suppliers: [],
    discounts: [],
    paymentTypesOptions: [],
    organizations: [],
    doctorClinicId: null,
    region: process.env.VUE_APP_REGION,
    organizationDrugbookOn: false,
  }),
  methods: {
    ...mapActions("admin", ["getSettingsByKey"]),
    ...mapActions("organization", [
      "fetchOrganizations",
      "createOrganizationClinic",
      "getAllSupplierList",
      "getAllDiscountList",
      "fetchOrganizationAssignList",
      "fetchOrganizationDetails"
    ]),
    // async getSuppliersList() {
    //   const res = await getAllSupplierList();
    //     this.getSupplierList().then(async (res) => {
    //   self.supplierData = res.data.data.docs;
    //   self.totalDocs = res.data.data.pagination.total || 0;
    //   self.page = res.data.data.pagination.page;
    //   self.currentPage = res.data.data.pagination.page;
    //   self.noDataText = 'No Suppliers Available'
    //     });
    // },
    async getOrganizationList(id) {
      this.fetchOrganizationAssignList({ id: id }).then((res) => {
        this.organizations = res.data.data;
      });
      this.serverResponded = true;
    },
    // getOrganizationList() {
    //   this.$vs.loading();
    //   let self = this;
    //   this.fetchOrganizations(self.dataTableParams).then((res) => {
    //     this.$vs.loading.close();
    //     self.noDataText = "No Organization Available";
    //     self.serverResponded = true;
    //     self.users = res.data.data.docs;
    //     self.totalDocs =
    //       res.data.data.pagination.total || res.data.data.pagination[0].total;
    //     self.page =
    //       res.data.data.pagination.page || res.data.data.pagination[0].page;
    //     self.currentPage =
    //       res.data.data.pagination.page || res.data.data.pagination[0].page;
    //   });
    // },

    async ceateClinic() {
      try {
        let isValid = await this.$validator.validate();

        if (isValid) {
          const user = JSON.parse(
            localStorage.getItem(`${process.env.VUE_APP_PROCESS_TOKEN}User`)
          );

          let tempDetails = this.clinicDetails;
          
          tempDetails.user = user.firstName + " " + user.lastName;

          tempDetails.invoiceEmail =
            this.clinicDetails.invoiceEmail === ""
              ? []
              : this.clinicDetails.invoiceEmail;

          tempDetails.orderRecipients =
            this.clinicDetails.orderRecipients === ""
              ? []
              : this.clinicDetails.orderRecipients.split(",");
              
          if (this.doctorClinicId) {
            tempDetails.doctorClinicId = this.doctorClinicId;
          }
          await this.createOrganizationClinic(tempDetails);
          this.$vs.notify({
            title: "Added new clinic",
            text: "New clinic successfully created.",
            color: "success",
          });
          this.popupActive = false;
          this.$router.go(-1);
        }
      } catch (err) {
        if (err.status === 422) {
          this.$vs.notify({
            title: "Failed",
            text: "Failed to add new clinic. Try again!",
            color: "danger",
          });
        }
        if (err.status === 409) {
          this.$vs.notify({
            title: "Failed",
            text: err.data.message,
            color: "danger",
          });
          this.errors.add({
            field: "email",
            msg: err.data.message,
          });
        }
      }
    },
    async getAllDiscounts() {
      const res = await this.getAllDiscountList();
      if (res.data.data) {
        this.discounts = res.data.data;
      }
    },
    async getAllSuppliers() {
      const res = await this.getAllSupplierList();
      if (res.data.data) {
        this.suppliers = res.data.data;
      }
    },
    setInvoiceEmail(value) {
      if (value) {
        setTimeout(() => {
          this.clinicDetails.invoiceEmail = _.find(this.organizations, [
            "_id",
            value,
          ]).monthTreatmentInvoiceEmail;
          this.clinicDetails.orderRecipients = _.find(this.organizations, [
            "_id",
            value,
          ]).monthTreatmentInvoiceEmail;
        }, 1500);
      }
    },
    async setOrganizationDrugbookOn(value) {
      try {
        if (value) {
          const res = await this.fetchOrganizationDetails(value)
          this.organizationDrugbookOn = res.data.data.isDrugbookEnabled;
          this.clinicDetails.isDrugBookEnabled = res.data.data.isDrugbookEnabled;
          this.clinicDetails.isBYODoctor = res.data.data.isByo;
          if (!this.organizationDrugbookOn) {
            this.clinicDetails.isDrugBookEnabled = false;
            this.clinicDetails.isDrugBookEnabled = res.data.data.isDrugbookEnabled;
          }
        }
      } catch (error) {
        console.error(error);
      }
    },
    regionType() {
      const regions = {
        US: "us",
        AU: "au",
        NZ: "nz"
      }
      return regions[this.region]
    },
    async getPaymentTypes() {
      try {
        const res = await this.getSettingsByKey("paymentOptionTypes");
        this.paymentTypesOptions = res.data.data.value;
      } catch (error) {
        console.log(error)
      }
    }
  },
  mounted() {
    this.autocomplete = new google.maps.places.Autocomplete(
      this.$refs.autocomplete,
      { types: ["geocode"], componentRestrictions: { country: this.regionType() } }
    );
    this.autocomplete.addListener("place_changed", () => {
      let place = this.autocomplete.getPlace();
      this.errors.remove("displayAddress");
      let ac = place.address_components;
      this.checkDisplayAddress = place.formatted_address;
      let lat = place.geometry.location.lat();
      let lon = place.geometry.location.lng();
      this.clinicDetails.address.displayAddress = place.formatted_address;
      this.clinicDetails.address.coordinates.longitude = lon;
      this.clinicDetails.address.coordinates.latitude = lat;
      this.clinicDetails.address.suburb =
        place.address_components[0].short_name;
      this.clinicDetails.address.state = place.address_components[1].short_name;
    });
    mapboxgl.accessToken = this.accessToken;

    var geocoder = new MapboxGeocoder({
      accessToken: mapboxgl.accessToken,
      types: "address",
      countries: this.regionType(),
      placeholder: "Search Address",
    });

    geocoder.addTo("#geocoder");
    geocoder.on("result", (e) => {
      this.clinicDetails.address.suburb = e.result.context.filter((item) => item.id.includes(this.region === "US" ? "district" : "locality"))[0] ? e.result.context.filter((item) => item.id.includes(this.region === "US" ? "district" : "locality"))[0].text : '';
      this.clinicDetails.address.state = e.result.context.filter((item) => item.id.includes("region"))[0] ? e.result.context.filter((item) => item.id.includes("region"))[0]["text_en-US"] : '';
      this.clinicDetails.address.postCode = e.result.context.filter((item) => item.id.includes("postcode"))[0].text;
      this.clinicDetails.address.displayAddress = e.result.place_name;
      this.clinicDetails.address.coordinates.longitude =
        e.result.geometry.coordinates[0];
      this.clinicDetails.address.coordinates.latitude =
        e.result.geometry.coordinates[1];
    });
  },
  async created() {
    // await this.getSuppliersList();
    this.clinicDetails.organizationId = this.$route.params.organizationId
      ? this.$route.params.organizationId
      : null;
    this.doctorClinicId = this.$route.params.doctorClinicId;
    await this.getOrganizationList(this.$store.state.AppActiveUser._id);
    await this.getAllDiscounts();
    await this.getAllSuppliers();
    await this.getPaymentTypes();
  },
  watch: {
    "clinicDetails.organizationId": function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.clinicDetails.organizationId = newVal;
        this.setOrganizationDrugbookOn(newVal);
        this.setInvoiceEmail(newVal);
      }
    },
  },
};
</script>

<style>
.mapboxgl-ctrl-geocoder {
  width: 100% !important;
  max-width: unset !important;
}
</style>
